// src/components/HomeSec4.js
import React, { useState, useEffect } from "react";
import client from "../../sanityCli";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

function HomeSec4() {
  const [data, setData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeSec4"][0]{
        heading,
        title,
        images[]{
          asset->{
            _id,
            url
          }
        }
      }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (!data) return <div></div>;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <div
      id="gallery"
      className="gallery px-[5%] my-[5%] flex flex-col items-center justify-center space-y-8"
    >
      <p
        className="gallery-heading text-[1.75vw] leading-[3vw] font-semibold"
        style={{ color: "#6D7A65" }}
      >
        {data.heading}
      </p>
      <h1 className="text-[3.75vw] leading-[4.25vw] font-bold playfair-display text-gray-700">
        {data.title}
      </h1>

      <div className="gallery-box-container w-full">
        {isMobile ? (
          <Slider {...settings}>
            {data.images &&
              data.images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image.asset.url}
                    alt="Gallery Item"
                    className="w-full"
                  />
                </div>
              ))}
          </Slider>
        ) : (
          <div className="flex items-center gap-6">
            {data.images &&
              data.images.map((image, index) => (
                <div key={index} className="w-1/4">
                  <img src={image.asset.url} alt="Gallery Item" />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeSec4;
