import React, { useState, useEffect } from "react";
import client, { urlFor } from "../../sanityCli";
import { v4 as uuid4 } from "uuid";

function HomeSec12() {
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [formData, setFormData] = useState({
    firstName: "",
    phone: "",
    company: "",
    email: "",
    message: "",
  });

  const fetchData = async () => {
    try {
      const result = await client.fetch('*[_type == "contactSection"][0]');
      setContent(result);
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    await fetchData();
    if (!content) {
      setIsLoading(false); // Stop loading if no content
      return;
    }

    client
      .patch(content._id)
      .setIfMissing({ formSubmissions: [] })
      .append("formSubmissions", [
        {
          ...formData,
          _key: uuid4(),
          submittedAt: new Date().toISOString(),
        },
      ])
      .commit()
      .then(() => {
        alert("Form submitted successfully!");
        setFormData({
          firstName: "",
          phone: "",
          company: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        alert("Failed to submit the form. Please try again.");
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  if (!content) return <div></div>;

  return (
    <div
      id="contact"
      className="contact flex flex-col lg:flex-row items-start justify-start lg:p-[5.25vw] p-4 my-12 gap-10"
    >
      <div className="contact-left w-full lg:w-1/3 flex flex-col items-center lg:items-start space-y-6">
        <p
          className="text-[.7rem] leading-[1rem] lato lg:text-xl font-normal tracking-widest lg:leading-[1.75vw] text-white px-4 py-1 rounded-full"
          style={{
            background: "linear-gradient(270deg, #9F0036 0%, #E1306C 100%)",
          }}
        >
          {content.letsConect}
        </p>
        <h1 className="text-[#E1306C] lato text-[1.5rem] montserrat leading-[1.7rem] lg:text-[3.5vw] lg:leading-[4vw] font-bold">
          {content.title}
        </h1>
        <p className="monstrrate text-center lg:text-start text-[.8rem] leading-[1.1rem] md:text-xl lg:text-[1.2vw] lg:leading-[1.8vw] text-[#595566]">
          {content.description}
        </p>
        <div className=" hidden lg:flex flex-col lg:items-start items-center justify-between space-y-9">
          <div className="flex items-center justify-center gap-2 lg:gap-5">
            <div>
              <img
                src={urlFor(content.phoneIcon).url()}
                alt="/"
                className="w-12"
              />
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.1vw] lg:leading-[2.1rem] text-[#595566]">
                {content.callLable}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw] font-medium">
                {content.phone}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div>
              <img
                src={urlFor(content.dayIcon).url()}
                alt="/"
                className="w-12"
              />
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem] text-[#595566]">
                {content.dayLabbel}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw] font-medium">
                {content.workingDays}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div className="w-20">
              <img
                src={urlFor(content.officeIcon).url()}
                alt="/"
                className="w-12"
              />
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem] text-[#595566]">
                {content.locationLable}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw] font-medium">
                {content.address}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div className="bg-[#FFD9E6] w-10 h-10 flex items-center justify-center rounded-full">
             {content.emailIcon &&  <img
                src={urlFor(content.emailIcon).url()}
                alt="/"
                className="w-6"
              />}
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem] text-[#595566]">
                {content.emailLabel}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw] font-medium">
                {content.emailAdd}
              </p>
            </div>
          </div>
        </div>
        <div className="block w-full lg:hidden">
          <div className="flex items-center justify-center w-full mt-8">
            <div className="flex flex-col items-center justify-center gap-2">
              <div>
                <img
                  src={urlFor(content.phoneIcon).url()}
                  alt="/"
                  className="w-7"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem] text-[#595566]">
                  {content.callLable}
                </p>
                <p className="text-[#2B3029] monstrrate text-[.8rem]">
                  {content.phone}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-8">
            <div className="flex flex-col items-center justify-center gap-2">
              <div>
                <img
                  src={urlFor(content.dayIcon).url()}
                  alt="/"
                  className="w-7"
                />
              </div>
              <div className="flex flex-col items-start ">
                <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem] text-[#595566]">
                  {content.dayLabbel}
                </p>
                <p className="text-[#2B3029] monstrrate text-[.6rem]">
                  {content.workingDays}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-8">
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="">
                <img
                  src={urlFor(content.officeIcon).url()}
                  alt="/"
                  className="w-7"
                />
              </div>
              <div className="flex flex-col items-center ">
                <p className="monstrrate text-[.5rem]">
                  {content.locationLable}
                </p>
                <p className="text-[#2B3029] monstrrate text-[.6rem]">
                  {content.address}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center w-full mt-8">
            <div className="flex flex-col items-center justify-center gap-2">
              <div>
                {content.emailIcon && (
                  <img
                    src={urlFor(content.emailIcon)}
                    alt="/"
                    className="w-7"
                  />
                )}
              </div>
              <div className="flex flex-col items-center ">
                <p className="monstrrate text-[.5rem]">{content.emailLabel}</p>
                <p className="text-[#2B3029] monstrrate text-[.6rem]">
                  {content.emailAdd}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-right w-full lg:w-2/3 flex flex-col items-center lg:items-start  justify-center lg:px-8">
        <form
          onSubmit={handleSubmit}
          className="w-full space-y-6 lg:space-y-12"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Full Name */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm lg:text-[1.1vw] text-[#000] font-normal lato">
                {content.formLabels.firstName}
              </label>
              <input
                type="text"
                name="firstName"
                className="border rounded-xl w-full h-10 lg:h-12 px-4 focus:outline-none focus:ring-2 focus:ring-[#E1306C]"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="e.g., John David"
              />
            </div>
            {/* Phone */}

            <div className="flex flex-col space-y-2">
              <label className="text-sm lg:text-[1.1vw] text-[#000] font-normal lato">
                {content.formLabels.email}
              </label>
              <input
                type="email"
                name="email"
                className="border rounded-2xl w-full h-10 lg:h-12 px-4 focus:outline-none focus:ring-2 focus:ring-[#E1306C]"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="e.g., consult@mail.com"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Email */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm lg:text-[1.1vw] text-[#000] font-normal lato">
                {content.formLabels.phone}
              </label>
              <input
                type="tel"
                name="phone"
                className="border rounded-xl w-full h-10 lg:h-12 px-4 focus:outline-none focus:ring-2 focus:ring-[#E1306C]"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="e.g., +91 9486542317"
              />
            </div>

            {/* Company */}
            <div className="flex flex-col space-y-2">
              <label className="text-sm lg:text-[1.1vw] text-[#000] font-normal lato">
                {content.formLabels.company}
              </label>
              <input
                type="text"
                name="company"
                className="border rounded-xl w-full h-10 lg:h-12 px-4 focus:outline-none focus:ring-2 focus:ring-[#E1306C]"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="e.g., Yourcompany.com"
              />
            </div>
          </div>
          {/* Message */}
          <div className="flex flex-col space-y-2">
            <label className="text-sm lg:text-[1.1vw] text-[#000] font-normal lato">
              {content.formLabels.message}
            </label>
            <textarea
              name="message"
              placeholder="Type Here.."
              className="border rounded-xl  w-full min-h-[8rem] lg:min-h-[12rem] p-4 focus:outline-none focus:ring-2 focus:ring-[#E1306C]"
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
          </div>
          {/* Submit Button */}
          <div className="flex justify-center lg:justify-end">
            {isLoading ? (
              <div className="loader text-[#E1306C] font-medium text-sm lg:text-base">
                Submitting...
              </div>
            ) : (
              <button
                type="submit"
                className="bg-[#E1306C] montserrat text-white text-sm lg:text-base font-semibold px-6 py-2 lg:py-3 rounded-xl shadow hover:bg-[#c12758] focus:ring-2 focus:ring-[#E1306C] focus:ring-offset-2 transition-all"
              >
                {content.sendBtn}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default HomeSec12;
