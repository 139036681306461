import React, { useState, useEffect } from "react";
import client from "../sanityCli.js";
import Navbar from "../navbar/Navbar";
import HomeHero from "./Home-1/HomeHero";
import HomeSec2 from "./Home-2/HomeSec2";

import HomeSec5 from "./Home-5/HomeSec5";

import HomeSec8 from "./Home-8/HomeSec8";

import HomeSec11 from "./Home-11/HomeSec11";
import HomeSec12 from "./Home-12/HomeSec12";
// import HomeSec13 from "./Home-13/HomeSec13";
import Footer from "./Home-14/Footer.js";

function HomeComponents() {
  const [heroData, setHeroData] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading status

  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
    const fetchData = async () => {
      try {
        const result = await client.fetch(`*[_type == "homeHero"][0]`);
        setHeroData(result);

        // Once data is fetched, set loading to false
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  // Render loading indicator while data is being fetched
  if (loading) {
    return <div></div>;
  }

  return (
    <div>
      <Navbar />
      <HomeHero />
      <HomeSec2 />
      <HomeSec8 />
      <HomeSec5 />

  

      <HomeSec11 />
      <HomeSec12 />

      <Footer />
    </div>
  );
}

export default HomeComponents;
