import React, { useState, useEffect } from "react";
import client, { urlFor } from "../../sanityCli";

function HomeSec2() {
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeSec2"][0]{
          sectionTitle,
          mainTitle,
          description,
          vision1Title,
          vision1Description,
          vision2Title,
          vision2Description,
          "image1Url": image1.asset->url,
          "image2Url": image2.asset->url
        }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div
      id="about"
      className="flex flex-col-reverse lg:flex-row items-center gap-20 py-4 px-8 lg:my-0 my-4 lg:p-[5.25vw]"
    >
      {/* Left Section */}
      <div className="lg:w-1/2 flex-col space-y-4">
        <p className="text-[#E1306C] text-xl lg:text-2xl font-semibold text-center lg:text-left">
          {data.sectionTitle}
        </p>
        <h1 className="text-[#000] my-2 text-2xl lg:text-[3.25vw] lg:leading-[4.35vw] font-semibold capitalize text-center lg:text-left playfair-display ">
          {data.mainTitle}
        </h1>
        <p className="text-[#2D2D2D] monstrrate text-lg leading-relaxed text-center lg:text-left my-2">
          {data.description}
        </p>
      </div>

      {/* Right Section */}
      <div className="lg:w-1/2 flex flex-col md:flex-row items-center gap-8">
        {/* Vision 1 */}
        <div
          className="p-6 rounded-xl shadow-lg flex flex-col items-center md:items-start md:space-y-4"
          style={{
            borderRadius: "16px",
            background: "linear-gradient(180deg, #E1306C 0%, #9F0036 100%)",
            boxShadow:
              "0px 2px 1px 0px rgba(0, 0, 0, 0.09), 0px 4px 2px 0px rgba(0, 0, 0, 0.09), 0px 8px 4px 0px rgba(0, 0, 0, 0.09), 0px 16px 8px 0px rgba(0, 0, 0, 0.09), 0px 32px 16px 0px rgba(0, 0, 0, 0.09)",
          }}
        >
          {data.image1Url && (
            <img
              src={data.image1Url}
              alt="Vision 1"
              className="w-16 mb-4 bg-[#FDF2F7] rounded-lg"
            />
          )}
          <h2 className="text-xl lg:text-[1.65vw] font-bold text-center md:text-left text-[#FDF2F7] monstrrate">
            {data.vision1Title}
          </h2>
          <p className="text-base lg:text-lg leading-relaxed text-center md:text-left text-[#FDF2F7] monstrrate">
            {data.vision1Description}
          </p>
        </div>

        {/* Vision 2 */}
        <div
          className="p-6 rounded-xl shadow-lg flex flex-col items-center md:items-start md:space-y-4"
          style={{
            borderRadius: "16px",
            background: "#FDF2F7",
            boxShadow: "0px 2px 1px 0px rgba(0, 0, 0, 0.09)",
          }}
        >
          {data.image2Url && (
            <img
              src={data.image2Url}
              alt="Vision 2"
              className="w-16 mb-4 p-2 rounded-lg"
              style={{
                background: "linear-gradient(180deg, #E1306C 0%, #9F0036 100%)",
              }}
            />
          )}
          <h2 className="text-xl md:text-[1.65vw] font-bold text-center md:text-left text-[#E1306C] monstrrate">
            {data.vision2Title}
          </h2>
          <p className="text-base lg:text-lg leading-relaxed text-center md:text-left text-[#E1306C] monstrrate">
            {data.vision2Description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeSec2;
