import React from "react";
import frame from './Frame.png';

function BrandEmpowermentSection() {
  return (
    <div className="p-6 lg:p-[5.25vw]  bg-white text-center" id="sponsor">
      <h2 className="text-xl text-[#E1306C] font-semibold mb-4 md:text-[3.25vw] playfair-display tracking-widest">
        Empower Your Brand with Namasvi
      </h2>
      <p className="text-[#000] monstrrate lg:text-[1.5vw] lg:leading-[2.5vw] text-lg lg:max-w-4xl mx-auto mt-8 mb-12 lg:my-10">
        From tracking challenge analytics to sponsoring events, Namasvi's Sponsor App offers powerful tools to connect your brand with the right audience. Elevate your marketing strategy and engage with a community that matters.
      </p>
      <div className="flex items-center justify-center gap-6">
          <img src={frame} alt="frame" className="w-full lg:w-1/2"/>
      </div>
    </div>
  );
}

export default BrandEmpowermentSection;


// import React, { useState, useEffect } from "react";
// import client, {urlFor} from "../../sanityCli";
// // import "./HomeSec11.css";

// function HomeSec11() {
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     client
//       .fetch(
//         `*[_type == "homeSec11"]{
//           heading,
//           description,
//           "imageUrl": image.asset->url
//         }`
//       )
//       .then((data) => setData(data[0]))
//       .catch(console.error);
//   }, []);

//   if (!data) return <div></div>;

//   return (
//     <div className="my-[10%] flex flex-col items-center justify-center">
//       <p className="heading-color playfair-display text-[1.5rem] lg:text-[3.75vw] leading-[4.25vw] font-semibold">{data.heading}</p>
//       <p className="monstrrate font-normal text-[1rem] leading-6 lg:text-[1.8vw] lg:leading-[2.8vw] text-[#353C33] my-8 text-center px-[4%]">
//         {data.description}
//       </p>
//       <img src={data.imageUrl} alt="Global Reach" className="w-full my-2" />
//     </div>
//   );
// }

// export default HomeSec11;

