// import sanityClient from "@sanity/client";
import sanityClient from '@sanity/client'
// import imageUrlBuilder from '@sanity/image-url';
import imageUrlBuilder from '@sanity/image-url'



const client =sanityClient({
    projectId: '6868y1b7', // Replace with your project ID
    dataset: 'production',     // Replace with your dataset name
    token: process.env.REACT_APP_SANITY_TOKEN,
})

const builder = imageUrlBuilder(client);

export const urlFor = (source) => {
    return builder.image(source);
};

export default client;