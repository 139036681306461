import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import client, { urlFor } from "../../sanityCli";

function Footer() {
  const [footer, setFooter] = useState(null);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const footerResult = await client.fetch('*[_type == "footer"][0]');
        setFooter(footerResult);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchFooterData();
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!footer) return null;

  return (
    <footer className="bg-[#FDF2F7] px-[5%] py-[5%]">
      {/* Desktop Footer */}
      <div className="hidden md:flex justify-between">
        {/* Company Section */}
        {footer.company && (
          <div>
            <h1 className="monstrrate text-[1vw] font-semibold text-gray-800">
              {footer.company.title}
            </h1>
            <div className="mt-4 space-y-2">
              {/* {[footer.company.itme1, footer.company.itme2, footer.company.itme3].map(
                (item, index) =>
                  item && (
                    <p
                      key={index}
                      className="monstrrate text-[1vw] text-gray-800 cursor-pointer"
                      onClick={() => handleNavLinkClick("about")}
                    >
                      {item}
                    </p>
                  )
              )} */}

              <p
                className="monstrrate text-[1vw] text-gray-800 cursor-pointer"
                onClick={() => handleNavLinkClick("about")}
              >
                {footer.company.itme1}
              </p>
              <p
                className="monstrrate text-[1vw] text-gray-800 cursor-pointer"
                onClick={() => handleNavLinkClick("ourProducts")}
              >
                {footer.company.itme2}
              </p>
              <p
                className="monstrrate text-[1vw] text-gray-800 cursor-pointer"
                onClick={() => handleNavLinkClick("participate")}
              >
                {footer.company.itme3}
              </p>
            </div>
            <a href="https://indiclancers.com" className="">
              <img
                src={urlFor(footer.indicLogo)}
                alt="indic-logo"
                className="w-[20vw] mt-8"
              />
            </a>
          </div>
        )}

        {/* Get In Touch Section */}
        {footer.getInTouch && (
          <div>
            <h1 className="monstrrate text-[1vw] font-semibold text-gray-800">
              {footer.getInTouch.title}
            </h1>
            <p
              className="monstrrate text-[1vw] text-gray-800 cursor-pointer mt-4"
              onClick={() => handleNavLinkClick("contact")}
            >
              {footer.getInTouch.itme1}
            </p>
          </div>
        )}

        {/* Logo and Legal Section */}
        <div>
          {footer.expoLogo && (
            <img
              src={urlFor(footer.expoLogo)}
              alt="Expo Logo"
              className="w-[10vw] cursor-pointer mb-4"
              onClick={() => handleNavLinkClick("home")}
            />
          )}
          {footer.copyright && (
            <p className="monstrrate text-[1vw] text-gray-800">
              {footer.copyright}
            </p>
          )}
          <div className="flex gap-4 mt-4">
            {footer.legals && footer.legals.itme1Path && (
              <Link
                to={footer.legals.itme1Path}
                className="monstrrate text-[1vw] text-gray-800 underline"
              >
                {footer.legals.itme1}
              </Link>
            )}
            {footer.legals && footer.legals.itme2Path && (
              <Link
                to={footer.legals.itme2Path}
                className="monstrrate text-[1vw] text-gray-800 underline"
              >
                {footer.legals.itme2}
              </Link>
            )}
            {footer.legals && footer.legals.itme3Path && (
              <Link
                to={footer.legals.itme3Path}
                className="monstrrate text-[1vw] text-gray-800 underline"
              >
                {footer.legals.itme3}
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Footer */}
      <div className="md:hidden flex flex-col items-start space-y-6 px-2">
        {footer.expoLogo && (
          <img
            src={urlFor(footer.expoLogo)}
            alt="Expo Logo"
            className="w-[40vw] cursor-pointer"
            onClick={() => handleNavLinkClick("home")}
          />
        )}
        <div className="flex items-start justify-between w-full">
          {footer.company && (
            <div className="text-start">
              <h1 className="monstrrate text-[4vw] font-semibold text-gray-800">
                {footer.company.title}
              </h1>
              <div className="mt-4 space-y-4">
                {[
                  footer.company.itme1,
                  footer.company.itme2,
                  footer.company.itme3,
                ].map(
                  (item, index) =>
                    item && (
                      <p
                        key={index}
                        className="monstrrate text-[4vw] text-[#313131] cursor-pointer"
                        onClick={() => handleNavLinkClick("about")}
                      >
                        {item}
                      </p>
                    )
                )}
              </div>
            </div>
          )}
          {footer.getInTouch && (
            <div>
              <h1 className="monstrrate text-[4vw] font-semibold text-gray-800">
                {footer.getInTouch.title}
              </h1>
              <p
                className="monstrrate text-[4vw] text-[#313131] cursor-pointer mt-4"
                onClick={() => handleNavLinkClick("contact")}
              >
                {footer.getInTouch.itme1}
              </p>
            </div>
          )}
        </div>
        <a href="https://indiclancers.com" className="">
          <img
            src={urlFor(footer.indicLogo)}
            alt="indic-logo"
            className="w-[40vw] mt-2"
          />
        </a>
        {footer.copyright && (
          <p className="monstrrate text-[4vw] text-[#313131]">
            {footer.copyright}
          </p>
        )}
       <div className="flex items-center gap-6 mt-2">
            {footer.legals && footer.legals.itme1Path && (
              <Link
                to={footer.legals.itme1Path}
                className="monstrrate text-xs text-gray-800 underline"
              >
                {footer.legals.itme1}
              </Link>
            )}
            {footer.legals && footer.legals.itme2Path && (
              <Link
                to={footer.legals.itme2Path}
                className="monstrrate text-xs text-gray-800 underline"
              >
                {footer.legals.itme2}
              </Link>
            )}
            {footer.legals && footer.legals.itme3Path && (
              <Link
                to={footer.legals.itme3Path}
                className="monstrrate text-xs text-gray-800 underline"
              >
                {footer.legals.itme3}
              </Link>
            )}
          </div>
      </div>
    </footer>
  );
}

export default Footer;
