import React from "react";
import image from "./bgImg.svg";
import icon from './icon1.svg'
import icon2 from './icon2.svg'
import icon3 from './icon3.svg'
import  icon4 from './icon4.svg'
import icon5 from './icon5.svg'

function ParticipationSection() {
  const steps = [
    {
      id: 1,
      title: "Download the App",
      description:
        "Get started by downloading the Namasvi app from the Play Store or App Store. Sign up to unlock a world of exciting challenges and rewards.",
      icon: icon,
      count: 789,
    },
    {
      id: 2,
      title: "Explore Challenges",
      description:
        "Browse through a variety of social media challenges hosted by top brands. Choose the ones that match your interests and creativity.",
      icon: icon2,
      count: 490,
    },
    {
      id: 3,
      title: "Participate and Create",
      description:
        "Follow the challenge instructions, create your unique content, and upload it directly to the app. Let your creativity shine!",
      icon: icon3,
      count: 786,
    },
    {
      id: 4,
      title: "Share and Engage",
      description:
        "Share your participation on social media platforms to maximize engagement. Gather likes, shares, and views to boost your chances of winning.",
      icon: icon4,
      count: 856,
    },
    {
      id: 5,
      title: "Win Exclusive Rewards",
      description:
        "Complete the challenge successfully and win exciting rewards, including brand hampers, coupons, and special perks. Celebrate your creativity with recognition and prizes!",
      icon: icon5,
      count: 856,
    },
  ];

  return (
    <div
      id="participate"
      className="py-12 px-6 lg:p-[5.25vw] bg-cover bg-center flex flex-col items-center object-cover"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <h2  className="text-center text-2xl lg:text-[1.75vw] font-semibold monstrrate text-[#E1306C] mb-4">
        How to Participate
      </h2>
      <h3 className="text-center text-2xl lg:text-[3.25vw] lg:leading-[4vw] lg:tracking-wide playfair-display font-semibold text-[#000] mb-12">
        How to Participate and Win Rewards in Namasvi!
      </h3>
      <div className="flex flex-wrap justify-center gap-8 w-full max-w-6xl mt-6">
        {steps.map((step, index) => (
          <div
            key={step.id}
            className={`bg-white shadow-lg rounded-xl p-6 text-center flex flex-col space-y-3 items-center ${
              index >= 3 ? "lg:w-[30%]" : "lg:w-[30%]"
            }`}
          >
            <div
              className="text-[#E1306C] text-2xl mb-4 w-12 h-12 rounded-full flex items-center justify-center"
              style={{
                background: "linear-gradient(180deg, #E1306C 0%, #9F0036 100%)",
              }}
            >
              <img src={step.icon} alt="icon" className="w-8 h-8"/>
            </div>
            <h4 className="text-lg md:text-[1.65vw] monstrrate font-extrabold text-[#E1306C] mb-2">
              {step.title}
            </h4>
            <p className="text-[#000] lg:text-[1.1vw] lg:leading-[1.65vw] font-medium monstrrate">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ParticipationSection;
