import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import client, { urlFor } from "../../sanityCli";

const HeroSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "heroSection"][0]{
          title,
          description,
          buttonText,
          buttonLinkId,
          backgroundGradient,
          image,
          playStoreImage
        }`
      )
      .then((data) => setHeroData(data))
      .catch(console.error);
  }, []);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    closeMobileMenu();
  };

  if (!heroData) return <div>Loading...</div>;

  return (
    <div
      className="flex flex-col lg:flex-row items-center justify-between text-white p-2 pb-5 md:p-[5.25vw] min-h-full"
      style={{
        background:
          heroData.backgroundGradient ||
          "linear-gradient(to right, #000, #333)",
      }}
    >
      <div className="flex-1 space-y-4 mb-8">
        <h1 className="text-xl leading-8 text-center lg:text-left md:text-[3.5vw] md:leading-[4.5vw] font-bold mt-5 uppercase poppins">
          {heroData.title}
        </h1>
        <p className="w-32 lg:w-40 h-[2px] lg:text-left text-center bg-[#FFF] mx-auto lg:mx-0">
          {" "}
        </p>
        <p className="text-base lg:text-lg text-center lg:text-left poppins">
          {heroData.description}
        </p>
        <div className="hidden lg:flex items-center justify-evenly lg:justify-start flex-row lg:gap-4">
          <button
            className="bg-white monstrrate text-[#E1306C] py-2 px-2 text-xs lg:text-lg lg:px-6  lg:py-3 rounded font-semibold hover:bg-gray-100 "
            onClick={() => handleNavLinkClick(heroData.buttonLinkId)}
          >
            {heroData.buttonText}
          </button>
          {heroData.playStoreImage && (
            <img
              src={urlFor(heroData.playStoreImage).url()}
              alt="Get on Google Play"
              className="h-12 lg:h-auto mt-2"
            />
          )}
        </div>
      </div>

      <div className="flex-1 flex justify-center my-8 md:my-0 relative">
        {heroData.image && (
          <img src={urlFor(heroData.image)} alt="right-image" />
        )}
      </div>
      <div className="flex lg:hidden items-center justify-evenly flex-row gap-4">
        <button
          className="bg-white monstrrate text-[#E1306C] py-2 px-2 text-xs lg:text-lg lg:px-6  lg:py-3 rounded font-semibold hover:bg-gray-100 "
          onClick={() => handleNavLinkClick(heroData.buttonLinkId)}
        >
          {heroData.buttonText}
        </button>
        <Link>
          {heroData.playStoreImage && (
            <img
              src={urlFor(heroData.playStoreImage)}
              alt="Get on Google Play"
              className="h-12 lg:h-auto mt-2"
            />
          )}
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;

// import React, { useState } from "react";
// import img from "./rightImg.svg";
// import { useNavigate, useLocation } from "react-router-dom";
// import playStore from "./googleplay.svg";

// const HeroSection = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const closeMobileMenu = () => {
//     setIsMobileMenuOpen(false);
//   };

//   const handleNavLinkClick = (sectionId) => {
//     if (location.pathname !== "/") {
//       navigate("/");
//       setTimeout(() => {
//         const element = document.getElementById(sectionId);
//         if (element) {
//           element.scrollIntoView({ behavior: "smooth" });
//         }
//       }, 500);
//     } else {
//       const element = document.getElementById(sectionId);
//       if (element) {
//         element.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//     closeMobileMenu(); // Close the mobile menu on nav link click
//   };

//   return (
//     <div
//       className="flex flex-col lg:flex-row items-center justify-between text-white p-2 pb-5 md:p-[5.25vw] min-h-full"
//       style={{
//         background:
//           "linear-gradient(279deg, #78052C 1.42%, #E1306C 98.43%), #FFF",
//       }}
//     >
//       <div className="flex-1 space-y-4 mb-8">
//         <h1 className="text-xl leading-8 text-center lg:text-left md:text-[3.5vw] md:leading-[4.5vw] font-bold mt-5 uppercase poppins">
//           TRANSFORMING SOCIAL MEDIA CHALLENGES INTO REAL REWARDS!
//         </h1>
//         <p className="w-32 lg:w-40 h-[2px] lg:text-left text-center bg-[#FFF] mx-auto lg:mx-0"> </p>
//         <p className="text-base lg:text-lg text-center lg:text-left poppins">
//           Join the excitement with Namasvi — your ultimate destination for
//           social media challenges, brand collaborations, and exclusive rewards.
//           Explore challenges, create content, and get rewarded for your
//           creativity!
//         </p>
//         <div className="hidden lg:flex items-center justify-evenly lg:justify-start flex-row lg:gap-4">
//           <button
//             className="bg-white monstrrate text-[#E1306C] py-2 px-2 text-xs lg:text-lg lg:px-6  lg:py-3 rounded font-semibold hover:bg-gray-100 "
//             onClick={() => handleNavLinkClick("ourProducts")}
//           >
//             Explore Our Services
//           </button>
//           <img
//             src={playStore}
//             alt="Get on Google Play"
//             className="h-12 lg:h-auto mt-2"
//           />
//         </div>
//       </div>

//       <div className="flex-1 flex justify-center my-8 md:my-0 relative">
//         <img src={img} alt="right-image" />
//       </div>
//       <div className="flex lg:hidden items-center justify-evenly flex-row gap-4">
//           <button
//             className="bg-white monstrrate text-[#E1306C] py-2 px-2 text-xs lg:text-lg lg:px-6  lg:py-3 rounded font-semibold hover:bg-gray-100 "
//             onClick={() => handleNavLinkClick("ourProducts")}
//           >
//             Explore Our Services
//           </button>
//           <img
//             src={playStore}
//             alt="Get on Google Play"
//             className="h-12 lg:h-auto mt-2"
//           />
//         </div>
//     </div>
//   );
// };

// export default HeroSection;

// import React, { useState, useEffect, useRef } from "react";
// import { useLocation, useNavigate, Link } from "react-router-dom";

// import "../../../index.css";

// function HomeHero() {
//   const [homeHeroData, setHomeHeroData] = useState(null);
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const slideIntervalRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const result = await client.fetch('*[_type == "homeHero"][0]');
//         setHomeHeroData(result);
//       } catch (error) {
//         console.error("Error fetching data from Sanity:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (
//       homeHeroData &&
//       homeHeroData.backgroundImages &&
//       homeHeroData.backgroundImages.length > 0
//     ) {
//       slideIntervalRef.current = setInterval(() => {
//         setCurrentSlide(
//           (prevSlide) => (prevSlide + 1) % homeHeroData.backgroundImages.length
//         );
//       }, 5000); // Change slide every 5 seconds

//       return () => clearInterval(slideIntervalRef.current);
//     }
//   }, [homeHeroData]);

//   const location = useLocation();
//   const navigate = useNavigate();

//   const handleNavLinkClick = (sectionId) => {
//     if (location.pathname !== "/") {
//       navigate("/");
//       setTimeout(() => {
//         const element = document.getElementById(sectionId);
//         if (element) {
//           element.scrollIntoView({ behavior: "smooth" });
//         }
//       }, 500);
//     } else {
//       const element = document.getElementById(sectionId);
//       if (element) {
//         element.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//   };

//   if (!homeHeroData) {
//     return <div></div>;
//   }
//  console.log("homeHeroData", homeHeroData)
//   return (
//     <div
//       id="home"
//       className="home-hero relative overflow-hidden"
//     >
//       <div
//         className={`flex w-[${
//           homeHeroData.backgroundImages.length * 100
//         }vw] transition-transform duration-1000 transform`}
//         style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
//       >
//         {homeHeroData.backgroundImages.map((image, index) => (
//           <img
//             key={index}
//             src={urlFor(image).width(1920).url()}
//             alt="/"
//             className="w-[100vw] lg:h-[700px] md:h-[508px] h-[340px] object-cover"
//           />
//         ))}
//       </div>
//       <div className="overlay-text absolute  top-8 left-[0%] px-[2%]">
//         <div className="flex flex-col items-start justify-start lg:space-y-10 space-y-4">
//           <h1 className="playfair-display text-start text-white text-[1.25rem] leading-7 lg:text-[3.5vw] lg:leading-[4.5vw] tracking-wide-[6vw] font-bold">
//             {homeHeroData.title}
//           </h1>
//           <span className="bg-white w-32 h-1 rounded-sm"></span>
//           <p className="monstrrate text-[12px] md:text-[1rem] leading-5 lg:text-[1.25vw] text-start lg:leading-[2.6vw] text-white font-normal">
//             {homeHeroData.description}
//           </p>
//           <Link
//             to="/ourproducts"
//             className="w-fit text-[#E1306C] p-4 py-2 bg-white rounded-sm"
//           >
//             {homeHeroData.buttonText}
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default HomeHero;
