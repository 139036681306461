import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeHero from "./components/home/Home-1/HomeHero";
import HomeSec2 from "./components/home/Home-2/HomeSec2";
import App from "./App";
import HomeSec4 from "./components/unusable components/Home-4/HomeSec4";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./components/cookiesPolicy/CookiesPolicy";
import ProductDetail from './components/DetailProject/ProductDetail';
import Products from "./components/productsPage/Products";
import RefundPolicy from "./components/refundPolicy/RefundPolicy";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="home" element={<HomeHero />} />
        <Route path="home2" element={<HomeSec2 />} />
        <Route path="home4" element={<HomeSec4 />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="cookiePolicy" element={<CookiesPolicy />} />
        <Route path="refundPolicy" element={<RefundPolicy />} /> 
        <Route path="product/:productType" element={<ProductDetail />} />
        <Route path="ourproducts" element ={< Products/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
