import React from "react";

import Navbar from "../navbar/Navbar";
import DetailPage2 from "./DetailPage2";
import Footer from "../home/Home-14/Footer";
import HomeSec12 from "../home/Home-12/HomeSec12";
import HeroSection from "../home/Home-1/HomeHero";


const ProductDetail = () => {
  return (
    <div>
      <Navbar />
       <HeroSection />
       <DetailPage2 />
       <HomeSec12 />
       <Footer />
    </div>
  );
};

export default ProductDetail;
