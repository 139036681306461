import React, { useState, useEffect } from "react";
import client from "../../sanityCli";
import { useNavigate } from "react-router-dom";

function HomeSec5() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeSec5"][0]{
          heading,
          title,
          products[]{
            image{
              asset->{
                _id,
                url
              }
            },
            icon{
              asset->{
                _id,
                url
              }
            },
            label
          }
        }`
      )
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  const handleNavigationClick = (index) => {
    const productTypes = ["Influencer", "MassMedia", "DigitalMarketing", "SocialMedia"];
    if (productTypes[index]) {
      navigate(`/product/${productTypes[index]}`);
      setActiveIndex(index);
    }
  };

  if (error) return <div>Error loading data</div>;
  if (!data) return <div></div>;

  return (
    <div
      id="ourProducts"
      className="py-4 px-8 pb-12 lg:p-[5.25vw] flex flex-col items-center bg-[#FDF2F7]"
    >
      {/* Heading Section */}
      <div className="flex flex-col items-center justify-center  lg:space-y-2">
        <p className="monstrrate text-[#E1306C] text-base font-semibold leading-10 lg:text-[1.75vw] lg:eading-[2.75vw]">
          {data.heading}
        </p>
        <h1 className="text-[#000] text-2xl leading-8 playfair-display font-semibold lg:text-[3.25vw] lg:leading-[5.25vw] lg:px-[10vw] text-center m-0">
          {data.title}
        </h1>
      </div>

      {/* Products Section */}
      <div className="mt-10 grid grid-cols-1 lg:grid-cols-4 gap-8 w-full">
        {data.products &&
          data.products.map((product, index) => (
            <div
              key={index}
              className={` shadow-lg hover:shadow-xl rounded-lg overflow-hidden transition-transform transform  cursor-pointer ${
                activeIndex === index ? "ring-4 ring-green-300" : ""
              }`}
              onClick={() => handleNavigationClick(index)}
            >
              <div className="flex flex-col space-y-2 ">
                <img
                  src={product.image.asset.url}
                  alt={product.label}
                  className="w-full h-56 lg:h-64 object-cover"
                />
                <div className="rounded-md pl-2 py-2 pr-4 flex items-center gap-3 ">
                  <div className=" flex items-center justify-center">
                    <img
                      src={product.icon.asset.url}
                      alt={product.label}
                      className="w-10"
                    />
                  </div>
                  <p className="text-[#E1306C] text-base lg:text-[1.25vw] font-bold tracking-wide montserrat">
                    {product.label}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default HomeSec5;
